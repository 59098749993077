import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Getschemainfo from '../data/getschemainfo'

function SEO({ description, lang, meta, keywords, title, featuredImage, type, url, frontmatter, testimonies, body, installUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            featuredImage
            siteUrl
          }
        }
      }
    `
  )

  const postDescription = description?description:site.siteMetadata.description
  const postTitle = title || site.siteMetadata.title;
  const postCover = featuredImage ? featuredImage : site.siteMetadata.featuredImage
  const postUrl = `${site.siteMetadata.siteUrl}${url?url.pathname:''}`
  const postinstallUrl = installUrl?installUrl:'https://play.google.com/store/apps/developer?id=Museum+Buddy' //'https://apps.apple.com/gb/developer/vusiem-ltd/id551275215'
  let pageschema = null
  pageschema=Getschemainfo(postDescription, lang, meta, keywords, postTitle, postCover, type,
    postUrl, frontmatter, body, testimonies, site, postinstallUrl)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={postTitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: postDescription,
        },
        {
            property: `og:url`,
            content: postUrl
        },
        {
          property: `og:title`,
          content: postTitle,
        },
        {
          property: `og:description`,
          content: postDescription,
        },
        { property: "og:image",
          content: postCover },
        {
          property: `og:type`,
          content: type?type:`website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: postTitle,
        },
        {
          name: `twitter:description`,
          content: postDescription,
        },
      ]
        .concat(meta)}
    >
    {(pageschema) && (
      <script type="application/ld+json">
       {JSON.stringify(pageschema)}
      </script>
     )}
  </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
