import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Button from '@material-ui/core/Button';

function Copyright() {
  return (
    <React.Fragment>
      <div style={{display:' flex', flexDirection:' column'}}>
      <Typography variant='body2'>
      {'© '}

      <Link color="inherit" href="https://www.museum-buddy.com/">
        Trishti Systems Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      </Typography>

      <Typography variant='caption'>  All Rights Reserved</Typography>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    margin: 0,

    display: 'flex',
    backgroundColor: theme.palette.primary.xlight,
    width: '100%',
    maxWidth: '100vw'
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
    maxWidth: '90vw'
  },
  outlinedpaper: {
    border: `0px solid ${theme.palette.grey.main}`,
    margin: 0,
    marginTop: theme.spacing(1),
    backgroundColor: 'transparent',
    textAlign: 'center',
    padding:  theme.spacing(1, 3),
  },
  iconsWrapper: {
    paddingTop: 5,
    display: 'flex',
    marginBottom: 15
  },

  twittericon: {
    color: '#00acee'
  },
  facebookicon: {
    color: '#3b5998'
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    paddingRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

}));

export default function AppFooter() {
  const classes = useStyles();

  return (

      <Container className={classes.container}>
        <Typography component="footer" className={classes.root}>
        <Grid container direction= 'row' wrap='wrap-reverse'>
          <Grid item container xs={6} sm={6} md={4} >
            <div style={{display:'flex', flexDirection: ' column'}}>
            <Typography variant='h6'>
              About
              </Typography>

              <Copyright />
              </div>
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <Typography variant="h6" marked="left" gutterBottom component='body1'>
              Legal
            </Typography>
            <ul className={classes.list}>
              {/*<li className={classes.listItem}>
                <Link href="/terms/">Terms</Link>
              </li>*/}
              <li className={classes.listItem}>
                <Link href="/privacy/">Privacy</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4} md={6} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Button href={"/contactus/"} variant="contained" color="primary" className={classes.button} startIcon={<TouchAppIcon />}>
              Contact Us
            </Button>
            <div className={classes.outlinedpaper}>
              <Typography variant="body1" component="span">
                Got any questions? We are here to help. Get in touch!
              </Typography>
            </div>
            <div className={classes.iconsWrapper}>
            {/*<Typography variant='h6' style={{paddingRight: 5}}>
              Connect -
              </Typography>
            <div>
                <IconButton  size="small"  className={classes.twittericon}  aria-label="Twitter" href={'https://twitter.com/thevusiem'}>
                  <TwitterIcon/>
                </IconButton>
                <IconButton size="small" className={classes.facebookicon} aria-label="Facebook" href={'https://www.facebook.com/thevusiem'}>
                  <FacebookIcon/>
                </IconButton>
            </div>*/}
            </div>
          </Grid>
        </Grid>
        </Typography>
      </Container>

  );
}
