import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { withStyles } from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Home } from '@material-ui/icons';
import Toolbar, { styles as toolbarStyles } from '../atoms/Toolbar';
import {isMobile} from "react-device-detect";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from "@material-ui/core/Hidden";
import Menu from '@material-ui/core/Menu';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import MuseumIcon from '@material-ui/icons/AccountBalanceTwoTone';
import BlogIcon from '@material-ui/icons/Forum'
import { useEffect } from 'react'

const styles = theme => ({
  title: {
    fontSize: 24,
    color: 'red'
  },
  iconButton: {
    backgroundColor: 'rgba(255,245,255,0.1)',
    padding: 5,
    '&:hover': {
      backgroundColor: 'rgba(208,241,192,0.2)'
    }
  },
  iconStyle:{
    height: 45,
    width: 45,
    marginTop: 5
  },
  placeholder: toolbarStyles(theme).root,
  appbartransparent: {
    backgroundColor: 'transparent'
  },
  appbar: {
    background: 'rgb(251,233,231)',
    zIndex: 1000
  },
  toolbartransparent: {
    justifyContent: 'space-between',
    backgroundColor: 'transparent'
  },
  toolbarnormal: {
    justifyContent: 'space-between',
    opacity: 0.9,
    backgroundColor:theme.palette.grey.light
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  spread: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },
  linkSecondary: {
    color: theme.palette.primary.main,
  },
  museummenu: {
    maxHeight: 500,
    maxWidth: 200,
    padding: 2,
    margin: 10,
    opacity: 0.5,
    borderRadius: 10,
	position: 'relative',
	background: fade(theme.palette.grey.light, 0.9),
	border: `1px solid ${fade(theme.palette.grey.main, 0.3)}`,
  '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    fontWeight: 400,
    fontSize: 13.5

  },
},

  menuLink: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent'
  },
  menuitemlink: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color:  theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    backgroundColor: 'transparent'
  },
  nestedLink: {
    color: theme.palette.primary.light,
    fontSize: 12,
    textAlign: 'right'
  },
});

function MenuBar(props) {
  const { classes, museumsList } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerFlag, setDrawerFlag] = React.useState(false);
  const [museumsOpen, setMuseumsOpen] = React.useState(false);
  const [pageYposition, setPageYposition] = React.useState(0);

  const toggleDrawer = () => {
    setDrawerFlag(!drawerFlag)
  };
  const handleMuseumsClick = () => {
    setMuseumsOpen(!museumsOpen)
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
      const onScroll = e => {
        setPageYposition(window.pageYOffset)
      };
      window.addEventListener("scroll", onScroll, {passive: true});

      return () => window.removeEventListener("scroll", onScroll);
    }, [pageYposition]);

    const imageset = useStaticQuery(graphql`
      query logoimageQuery {
    logo: file(relativePath: {eq: "VusiemLogoMain.png"}) {
      childImageSharp {
        fluid(maxWidth: 100, webpQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

    `);

  const menuList = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.menuList}
    >

      <ListItem button onClick={handleMuseumsClick}>
        <ListItemIcon>
          <MuseumIcon />
        </ListItemIcon>
        <ListItemText primary="Museums"  className={classes.linkSecondary}/>
        {museumsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={museumsOpen} timeout="auto" unmountOnExit>
        <List component="nav" >
          {
            museumsList.map((museum, index)=>(
              <Link
                key={index}
                variant="body1"
                underline="none"
                className={classes.menuLink}
                href={museum.node.frontmatter.path}
              >
              <ListItem button className={classes.nestedLink}>
                <ListItemText primary={museum.node.frontmatter.title} />
              </ListItem>
            </Link>
            ))
          }
        </List>
      </Collapse>

       {/*<ListItem button onClick={toggleDrawer}>
        <ListItemIcon>
          <ServicesIcon />
        </ListItemIcon>
        <ListItemText primary="Services"  className={classes.linkSecondary}/>
      </ListItem>
      <Link href={'/blog'}>
      <ListItem button onClick={toggleDrawer}>
        <ListItemIcon>
          <BlogIcon />
        </ListItemIcon>
        <ListItemText primary="Blog"  className={classes.linkSecondary}/>
      </ListItem>
      </Link>*/}

    </List>
  );



  return (
    <main>
      <AppBar position="fixed" color='primary' className={classes.appbar}>
        <Toolbar className={pageYposition<100?classes.toolbartransparent:classes.toolbarnormal}>
          <div className={classes.left} >
          <Link href={'/'}>
            <IconButton className={classes.iconButton} >
              <Home className={classes.iconStyle}/>
              {/* <img src={'/buddyicon.svg'} className={classes.iconStyle}/> */}
            {/*<Img fluid={imageset.logo.childImageSharp.fluid} alt='Museum Buddy Logo' title='Home @ Museum Buddy'/>*/}
            </IconButton>
          </Link>
          </div>
          <Hidden mdUp>
          <IconButton edge="end" onClick={toggleDrawer} className={classes.linkSecondary} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Drawer open={drawerFlag} anchor="right" onClose={toggleDrawer} style={{maxWidth: '100vw'}}>
            {menuList()}
          </Drawer>

          </Hidden>
          <Hidden smDown>
          <div className={classes.rightLink}>
          <Link

            color="inherit"
            variant="h6"
            underline="none"
            className={clsx(classes.rightLink, classes.linkSecondary)}

            onClick={handleClick}
          >
            {'Museums'}
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            className={clsx(classes.rightLink, classes.linkSecondary)}
           href={"/contactus/"}
          >
            {'Contact Us'}
          </Link>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}

              PaperProps={{elevation: 5, className: classes.museummenu}}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
            >
            {
              museumsList.map((museum, index)=>(
                <Link
                  key={index}
                  variant="body1"
                  underline="none"
                  className={classes.menuLink}
                  href={museum.node.frontmatter.path}
                >
                <ListItem button className={classes.menuitemlink}>
                  <ListItemText primary={museum.node.frontmatter.title} />
                </ListItem>
              </Link>
              ))
            }

              {/*<MenuItem onClick={handleClose}>Berlin</MenuItem>
              <MenuItem onClick={handleClose}>Rome</MenuItem>*/}
            </Menu>
            {/*<Link
              variant="h6"
              underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
              href="/"
            >
              {'Services'}
            </Link>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
              href={'/blog'}
            >
              {'Blog'}
            </Link>*/}
          </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </main>
  );
}

MenuBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuBar);
