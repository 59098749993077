import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import MenuBar from "./MenuBar"
import Footer from "./Footer"


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      allMdx(
        filter: {frontmatter: {status: {eq: "publish"}, type: {eq: "museum"}}}
        sort: { fields: [frontmatter___sequence], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              name
              path
            }
          }
        }
      }
    }
    `}
    render={data => (
      <>
        <MenuBar siteTitle={data.site.siteMetadata.title} museumsList = {data.allMdx.edges}/>
          <main>{children}</main>
          <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
