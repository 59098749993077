
function Getschemainfo(postDescription, lang, meta, keywords, postTitle, postCover, type, postUrl, frontmatter, body, testimonies, site, installUrl) {
  let pageschema = null
  switch (type) {
    case "museum": pageschema =  {
    "@context" : "http://schema.org",
  "@type" : "MobileApplication",
  "applicationCategory" : "Travel",
  "applicationSubCategory" :  "Visitor Guides",
  "genre" : "Information",
  "name": frontmatter.appname,
  "alternateName": frontmatter.title,
  "description" : postDescription,
  "operatingSystem" : ["Android", "iOS"],
  "installUrl" : installUrl.ioSurl,
  "image": {
    "@type": "ImageObject",
    "url": site.siteMetadata.featuredImage,
    "width": 800,
    },
   "offers" : [{
     "@type" : "Offer",
     "url" : installUrl.ioSurl,
     "price": 0,
     "priceCurrency" : "GBP"
  },
  {
    "@type" : "Offer",
    "url" : installUrl.amazonurl,
    "price": 0,
    "priceCurrency" : "GBP"
 },
 {
   "@type" : "Offer",
   "url" : installUrl.androidurl,
   "price": 0,
   "priceCurrency" : "GBP"
},
]

      };
      break;

    case "blog": pageschema = {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage":{
      "@type":"WebPage",
      "@id":postUrl
      },
      "headline": postTitle,
      "operatingSystem": ["Android", "iOS"],
      "image": {
        "@type": "ImageObject",
        "url": postCover,
        "width": 800
        },
      "datePublished": frontmatter.date,
      "dateModified": frontmatter.date,
      "author": {
        "@type": "Person",
        "name": frontmatter.author
        },
      "publisher": {
        "@type": "Organization",
        "name": "Museum Buddy",
        "logo": {
          "@type": "ImageObject",
          "url": site.siteMetadata.featuredImage,
          "width": 80,
          "height": 80
            }
        },
      "description": postDescription,
      "articleBody": body
    };
    break;
    case "review": if (testimonies&&testimonies.length) {
    pageschema = {
        "@context": "https://schema.org/",
        "@type": "MobileApplication",
        "applicationCategory": "https://en.wikipedia.org/wiki/Virtual_museum",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "15"
        },
        "abstract": "Great Visitor Guide Apps for Museums",
        "name": "Museum Buddy Apps",
        "applicationSuite": "Museum Guides and Audio Tours",
        "author": "Trishti Systems Ltd",
        "image": site.siteMetadata.featuredImage,
        "review": testimonies.map(function (testimony) {
          return {
              "@type": "Review",
              "author": testimony.name,
              "datePublished": "2011-04-01",
              "description": testimony.details,
              "name": testimony.headline,
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
          }
        }) }};
    break;
    default: break;
  }
  
  return pageschema
};

export default Getschemainfo
